import React, { useContext, useState } from "react";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Header from "../../components/Header/index";
import ProfileForm from "./components/ProfileForm";
import Footer from "../../components/Footer";
import SpecLogoMain from "../../components/CustomIcons/SpecLogoMain";
import Store from "../../Store/Store";

const Profile = () => {
  const [creatingProfile, toggleCreatingProfile] = useState(false);
  const store = useContext(Store);

  return (
    <Grid sx={{ height: "100%" }} container direction={"row"}>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ marginTop: 10, marginBottom: 10, height: "100%" }}>
          {creatingProfile ? (
            <ProfileForm />
          ) : (
            <Box
              sx={{
                margin: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid
                container
                direction="column"
                sx={{
                  width: { xs: "300px", md: "448px" },
                  height: "335px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 4,
                }}
              >
                <Grid item>
                  <SpecLogoMain sx={{ fontSize: 80 }} />
                </Grid>
                <Grid item sx={{ marginTop: 2 }}>
                  <Typography variant="h2">Welcome to Spec</Typography>
                </Grid>
                <Grid item sx={{ marginTop: 2, width: "60%" }}>
                  <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                    Looks like you have not created a Spec Profile yet:
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 6,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{ width: "80%", height: "40px" }}
                    onClick={() => toggleCreatingProfile(true)}
                  >
                    <Typography variant="largeButton">
                      Create Profile
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                direction="column"
                sx={{
                  width: { xs: "300px", md: "448px" },
                  height: "278px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: (theme) => theme.palette.pure.white,
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.terrain[300],
                  borderRadius: 4,
                  marginTop: 8,
                }}
              >
                <Grid item sx={{ marginTop: 2 }}>
                  <Typography variant="h3">Something Gone Wrong?</Typography>
                </Grid>
                <Grid item sx={{ marginTop: 2, width: "80%" }}>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    If you are seeing this screen but you already have a Spec
                    Profile, please log out and check to make sure that you are
                    logging in using the correct Google, Facebook, or Apple
                    account.
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 6,
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      width: "80%",
                      textDecoration: "underline",
                      "&:hover": {
                        background: "none",
                        color: (theme) => theme.palette.primary[800],
                      },
                    }}
                    onClick={() => store.logout()}
                  >
                    <Typography variant="largeButton">Log Out</Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 6,
                  }}
                >
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    If you still having issues, please email us at{" "}
                    <Link
                      target="_blank"
                      href="mailto:contact@specapp.com"
                      color="#fff"
                      sx={{
                        fontWeight: 900,
                        color: (theme) => theme.palette.primary[400],
                        textDecoration: "underline",
                        "&:hover": {
                          color: (theme) => theme.palette.primary[800],
                        },
                      }}
                    >
                      contact@specapp.com
                    </Link>
                    !
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default Profile;

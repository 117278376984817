import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import SpecLogoMain from "../../../components/CustomIcons/SpecLogoMain";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import PhonelinkOutlinedIcon from "@mui/icons-material/PhonelinkOutlined";
import moment from "moment";
import useUserProfile from "../../../hooks/useUserProfile";
import { createEstablishmentReactQuery } from "../../../clients/establishmentsClient";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Store from "../../../Store/Store";
import inventoryClient from "../../../clients/inventoryClient";

const commonTimezones = [
  "Europe/London", // GMT/BST
  "Europe/Paris", // CET/CEST
  "America/New_York", // EST/EDT
  "America/Chicago", // CST/CDT
  "America/Denver", // MST/MDT
  "America/Los_Angeles", // PST/PDT
  "America/Anchorage",
  "Pacific/Honolulu",
  "Asia/Tokyo", // JST
  "Asia/Shanghai", // CST
  "Asia/Singapore", // SGT
  "Asia/Kolkata", // IST
  "Australia/Sydney", // AEST/AEDT,
  "Australia/Melbourne",
  "Australia/Brisbane",
  "Australia/Adelaide",
  "Australia/Perth",
  "Australia/Darwin",
  "Australia/Hobart",
];

const CreateEstablishmentGuide = ({ toggleHelpModal, toggleShowCreateNew }) => {
  const { data: userProfileData } = useUserProfile();
  const navigate = useNavigate();
  const store = useContext(Store);
  const queryClient = useQueryClient();

  const createQuickstartEstablishmentMutation = useMutation(
    createEstablishmentReactQuery
  );
  const seedInventoryMutation = useMutation(inventoryClient.seedInventoryReactQuery);

  const guideData = [
    {
      number: "1",
      icon: (
        <MapsHomeWorkOutlinedIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Create Establishment",
      description: "Set up your establishment details to begin",
    },
    {
      number: "2",
      icon: (
        <MenuBookOutlinedIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Build Your Library, Recipes, and Menus",
      description:
        "Build your inventory library, and link it when costing out recipes and menus",
    },
    {
      number: "3",
      icon: (
        <PhonelinkOutlinedIcon
          sx={{
            fontSize: { xs: 80, md: 120 },
            color: (theme) => theme.palette.terrain[400],
          }}
        />
      ),
      header: "Run Your F&B Program in Style...",
      description: "Explore all of the amazing features Spec has to offer",
    },
  ];

  const createQuickstart = async () => {
    const sandboxName = userProfileData.profile?.firstName
      ? `${userProfileData.profile?.firstName}'s Sandbox`
      : "My Sandbox";
    const timezoneGuess =
      commonTimezones.find((x) => x === moment.tz.guess()) ||
      "America/Los_Angeles";
    const quickstart = {
      active: true,
      address: {
        addressLine1: "123 Sandbox St",
        addressLine2: "Sandbox",
        city: "Awesome Town",
        postalCode: "11111",
        stateProvince: "CA",
      },
      display: true,
      imageURL: null,
      inventorySettings: {
        timezone: timezoneGuess,
        currency: timezoneGuess === "Europe/London" ? 2 : 0,
        locale: navigator.languages[0],
      },
      name: sandboxName,
    };

    const response = await createQuickstartEstablishmentMutation.mutateAsync(
      quickstart
    );
    queryClient.invalidateQueries({
      queryKey: ["my-memberships"],
    });
    queryClient.refetchQueries({
      queryKey: ["my-memberships"],
    });
    await seedInventoryMutation.mutateAsync({
      establishmentId: response.data.id,
      inventorySettings: quickstart.inventorySettings,
    });
    store.updateCurrentEstablishment(response.data);
    store.updateEstablishmentOptions([...store.establishments, response.data]);
    navigate(`/spec/${response.data.id}/dashboard`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain[300],
          borderRadius: 4,
          width: { xs: "300px", md: "920px" },
          padding: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 8,
            }}
          >
            <SpecLogoMain sx={{ fontSize: 80 }} />
            <Typography
              variant="h1"
              sx={{ textAlign: "center", fontSize: { xs: 24, md: 48 } }}
            >
              Welcome to Spec
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                marginTop: 2,
                fontSize: { xs: 12, md: 16 },
                paddingLeft: 4,
                paddingRight: 4,
              }}
            >
              Exploring Spec for the first time? Try our quickstart to create
              your own personal sandbox in 1 click where you can cost recipes,
              build menus, and more for free!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", marginTop: 6 }}
          >
            <Button
              variant="contained"
              sx={{ width: "212px", height: "40px", marginLeft: 1 }}
              onClick={createQuickstart}
              disabled={
                createQuickstartEstablishmentMutation.isLoading ||
                seedInventoryMutation.isLoading
              }
            >
              {createQuickstartEstablishmentMutation.isLoading ||
              seedInventoryMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Typography
                  variant="largeButton"
                  sx={{
                    fontSize: { xs: 10, md: 16 },
                    lineHeight: { xs: 1.2, md: 1.4 },
                  }}
                >
                  Quickstart
                </Typography>
              )}
            </Button>
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            marginTop={6}
            marginBottom={6}
          >
            <Divider sx={{ flexGrow: 1, bgcolor: "grey.400", height: 2 }} />
            <Typography
              variant="subtitle2"
              sx={{
                mx: 2,
                color: "grey.600",
                fontWeight: 500,
                whiteSpace: "nowrap",
              }}
            >
              or
            </Typography>
            <Divider sx={{ flexGrow: 1, bgcolor: "grey.400", height: 2 }} />
          </Box>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                marginTop: 2,
                fontSize: { xs: 12, md: 16 },
              }}
            >
              Ready to leverage Spec to make the most of your operations? Create
              your establishment below for free!
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", marginTop: 6 }}
          >
            <Button
              variant="outlined"
              sx={{
                height: "40px",
                width: "212px",
                marginRight: 1,
              }}
              onClick={() => toggleHelpModal(true)}
            >
              <Typography
                variant="largeButton"
                sx={{
                  fontSize: { xs: 10, md: 16 },
                  lineHeight: { xs: 1.2, md: 1.4 },
                }}
              >
                Need Help?
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{ width: "212px", height: "40px", marginLeft: 1 }}
              onClick={() => toggleShowCreateNew(true)}
            >
              <Typography
                variant="largeButton"
                sx={{
                  fontSize: { xs: 10, md: 16 },
                  lineHeight: { xs: 1.2, md: 1.4 },
                }}
              >
                Create Establishment
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%", padding: 12 }}>
            <Grid container direction="row" spacing={6}>
              {guideData.map((x) => (
                <Grid
                  key={x.id}
                  item
                  xs={12}
                  md={4}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: { xs: "180px", md: "242px" },
                      borderRadius: 3,
                      border: "1px solid",
                      borderColor: (theme) => theme.palette.terrain[300],
                      padding: 2,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Box
                        sx={{
                          width: "48px",
                          height: "48px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: (theme) =>
                            theme.palette.terrain[100],
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ color: (theme) => theme.palette.terrain[500] }}
                        >
                          {x.number}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {x.icon}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "90%",
                      marginTop: 4,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: 18, md: 24 },
                        lineHeight: { xs: 1.2, md: 1.4 },
                      }}
                    >
                      {x.header}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: 4,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        textAlign: "center",
                        fontSize: { xs: 12, md: 16 },
                        lineHeight: { xs: 1.2, md: 1.4 },
                      }}
                    >
                      {x.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateEstablishmentGuide;

import { useState, useEffect } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    // Cancel the timeout if value changes or the component unmounts.
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}
import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import AddDistributorCard from "./AddDistributorCard";
import DistributorFormFooter from "./DistributorFormFooter";
import DistributorFormHeader from "./DistributorFormHeader";
import DistributorStepper from "./DistributorStepper";
import CTABlock from "../../../components/CTABlock";
import Store from "../../../Store/Store";
import AddVendorRemovalConfirmationModal from "../../../components/Modals/AddVendorRemovalConfirmationModal";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
var moment = require("moment-timezone");

const AddDistributorForm = ({
  reloadVendors,
  toggleAddingDistributor,
  establishmentId,
  showSuccess,
  showError,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [newDistributors, setNewDistributors] = useState([]);
  const [userTimezones, setUserTimezones] = useState([]);
  const [isRemoveModalOpen, toggleIsRemoveModalOpen] = useState(false);
  const [vendorToRemoveId, setVendorToRemoveId] = useState(null);

  const store = useContext(Store);

  useEffect(() => {
    setUserTimezones(moment.tz.zonesForCountry(store.user.country || "us"));
  }, []);

  const addNewDistributor = (newDistributor) => {
    setNewDistributors([...newDistributors, newDistributor]);
  };

  const updateDistributor = (updatedDistributor) => {
    let newState = newDistributors.map((x) => {
      if (x.id === updatedDistributor.id) {
        return updatedDistributor;
      }
      return x;
    });
    setNewDistributors(newState);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const addDistributorMutation = useMutation(inventoryClient.addDistributors, {
    onSuccess: () => {
      reloadVendors();
      showSuccess("Vendors added successfully");
      setActiveStep(0);
      setNewDistributors([]);
      toggleAddingDistributor(false);
    },
    onError: () => {
      showError("There was an error adding the vendors. Please try again or contact support if the issue persists.");
    },
  });

  const handleAddDistributors = async () => {
    addDistributorMutation.mutate({ vendors: newDistributors, establishmentId: establishmentId });
  };

  const handleRemoveInProgressDistributor = () => {
    let newState = newDistributors.filter((x) => x.id !== vendorToRemoveId);
    setNewDistributors(newState);
    toggleIsRemoveModalOpen(false);

    if (newState.length === 0) {
      setActiveStep(0);
    }
  };

  const handleDeleteDistributor = (id) => {
    setVendorToRemoveId(id);
    toggleIsRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setVendorToRemoveId(null);
    toggleIsRemoveModalOpen(false);
  };

  return (
    <Box sx={{ marginBottom: "80px" }}>
      <Grid
        container
        direction="row"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Grid item xs={12} md={12} lg={4} sx={{ marginTop: 4 }}>
          <Typography variant="h2" sx={{ fontSize: { xs: 20, sm: 28 } }}>
            Add Vendors
          </Typography>
        </Grid>
        <DistributorStepper activeStep={activeStep} />
      </Grid>

      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DistributorFormHeader
            addNewDistributor={addNewDistributor}
            activeStep={activeStep}
          />
          <Box>
            {newDistributors.length === 0 ? (
              <CTABlock
                hideButton
                header="No Vendors Added"
                subheader="Add a vendor above to get started"
              />
            ) : (
              newDistributors.map((x) => (
                <AddDistributorCard
                  distributor={x}
                  step={activeStep}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  handleExpandClick={handleExpandClick}
                  key={x.id}
                  updateDistributor={updateDistributor}
                  timeZones={userTimezones}
                  onDelete={handleDeleteDistributor}
                />
              ))
            )}
          </Box>
        </Box>
      </>
      <DistributorFormFooter
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        newDistributors={newDistributors}
        submit={handleAddDistributors}
        isAddingDistributors={addDistributorMutation.isLoading}
      />
      <AddVendorRemovalConfirmationModal
        confirmationCallback={handleRemoveInProgressDistributor}
        isOpen={isRemoveModalOpen}
        closeModal={closeRemoveModal}
      />
    </Box>
  );
};

export default AddDistributorForm;

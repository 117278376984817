import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const AutoCompleteStyled = ({
  getOptionLabel,
  options,
  value,
  handleSelect,
  handleClear,
  label,
  isOptionEqualToFunc,
  tableView,
  modalView,
  type,
  error,
  disabled,
}) => {

  const handleOnChange = (e, newValue) => {
    if (newValue === null) {
      handleClear();
    } else {
      handleSelect(newValue);
    }
  };

  const renderOption = (props, option) => {
    return (
      <Box
        component="li"
        {...props}
        sx={{
          backgroundColor: (theme) => `${theme.palette.pure.white} !important`,
          "&:hover": {
            backgroundColor: (theme) =>
              `${theme.palette.terrain[300]} !important`,
          },
        }}
        key={option.id || option.name}
      >
        <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <Box
            sx={{
              width: "50%",
              "& .Mui-focused": {
                color: (theme) => `${theme.palette.terrain[300]} !important`,
              },
            }}
          >
            <Typography variant="body1">{option.name}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const optionComparer = (o, v) => {
    if (!v) {
      return false;
    }
    return o.name?.toLowerCase()?.includes(v.name.toLowerCase()) || o.id === -1;
  };

  return (
    <>
      <Autocomplete
        size="small"
        getOptionLabel={getOptionLabel}
        onChange={handleOnChange}
        sx={{
          "& .MuiAutocomplete-option.Mui-focused": {
            backgroundColor: (theme) =>
              `${theme.palette.terrain[300]} !!important`,
          },
        }}
        options={options}
        isOptionEqualToValue={isOptionEqualToFunc || optionComparer}
        disabled={disabled}
        renderInput={(params) => {
          if (tableView)
            return (
              <TextField
                variant={type === "outlined" ? "outlined" : "filled"}
                error={error}
                sx={{
                  "& .MuiFilledInput-root": {
                    background: (theme) => theme.palette.pure.white,
                    paddingBottom: 0,
                    paddingTop: 0,
                    marginBottom: "0.5px",
                  },
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  error: error,
                }}
              />
            );
          else
            return (
              <TextField
                {...params}
                placeholder={label}
                label={""}
                error={error}
                InputLabelProps={{ shrink: false }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    background: (theme) =>
                      modalView === true && theme.palette.pure.white,
                  },
                }}
              />
            );
        }}
        renderOption={renderOption}
        value={value}
      />
    </>
  );
};

export default AutoCompleteStyled;

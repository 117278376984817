import React, { useState } from "react";
import { Alert, Box, Button, Grid, Snackbar, Typography } from "@mui/material";
import DistributorCard from "./components/DistributorCard";
import EditDistributorModal from "../../components/Modals/EditDistributorModal";
import AddDistributor from "./components/AddDistributor";
import CTABlock from "../../components/CTABlock";
import DeleteDistributorModal from "../../components/Modals/DeleteDistributorModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SpecSpinner from "../../components/SpecSpinner";
import {useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import useVendors from "../../hooks/useVendors";

const Vendors = () => {
  const { establishmentid } = useParams();
  const [editDistributorModalOpen, toggleEditDistributorModalOpen] =
    useState(false);
  const [addingDistributor, toggleAddingDistributor] = useState(false);
  const [distributorToEdit, setDistributorToEdit] = useState(undefined);
  const [deleteDistributorModalOpen, toggleDeleteDistributorModalOpen] =
    useState(false);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const queryClient = useQueryClient();
  const [selectedDistributorId, setSelectedDistributorId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [{ isLoading: isLoadingVendors, data: vendorData, isError: isErrorLoadingVendors }, vendorMap] = useVendors(establishmentid);

  const handleDeleteModal = () => {
    toggleDeleteDistributorModalOpen(!deleteDistributorModalOpen);
  };

  const handleSelectDistributor = (id) => {
    setSelectedDistributorId(id);
  };

  const handleOpenEditModal = (distributor) => {
    setDistributorToEdit(distributor);
    toggleEditDistributorModalOpen(true);
  };

  const handleCloseEditDistributorModal = () => {
    setDistributorToEdit(undefined);
    toggleEditDistributorModalOpen(false);
  };

  const showSuccess = (msg) => {
    setAlertSuccess(msg);
  }

  const showError = (msg) => {
    setErrorMessage(msg);
  }

  const reloadVendors = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "vendors",
        {
          establishmentId: establishmentid,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "vendors",
        {
          establishmentId: establishmentid,
        },
      ],
    });
  };

  const handleCloseDeleteModal = () => {
    setSelectedDistributorId(null);
    toggleDeleteDistributorModalOpen(false);
  };

  const distributorUpdateCallback = (result) => {
    if (result.error) {
      setErrorMessage(
        "A problem occured while updating your distributor. Please refresh and try again."
      );
    } else {
      reloadVendors();
      setAlertSuccess("Success! Distributor updated.");
    }
    toggleEditDistributorModalOpen(false);
  };

  if (isLoadingVendors) {
    return <SpecSpinner open={true} text={"Loading Distributors..."} />;
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          padding: 4,
          marginTop: 6,
        }}
      >
        {isErrorLoadingVendors && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error fetching vendor data, please refresh
          </Typography>
        )}
        {addingDistributor ? (
          <AddDistributor
            toggleAddingDistributor={toggleAddingDistributor}
            establishmentId={establishmentid}
            reloadVendors={reloadVendors}
            showSuccess={showSuccess}
            showError={showError}
          />
        ) : (
          <>
            <Grid
              container
              sx={{
                maxWidth: "2440px",
                marginBottom: 6,
              }}
            >
              <Grid item xs={9}>
                <Typography variant="h2">Vendors</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    padding: 4,
                    justifySelf: "flex-end",
                    height: "40px",
                  }}
                  onClick={() => toggleAddingDistributor(true)}
                >
                  <AddOutlinedIcon sx={{ marginRight: 1, fontSize: 20 }} />
                  <Typography
                    variant="smallButton"
                    sx={{
                      fontSize: { xs: 10, md: 12 },
                      lineHeight: { xs: 1, md: 1.5 },
                    }}
                  >
                    Add Vendor
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid container sx={{ marginBottom: 2, width: "100%" }}>
              <Grid item xs={12}>
                <Typography variant="h3" sx={{ marginBottom: 6 }}>
                  Your Vendors
                </Typography>
                {vendorData?.vendors && vendorData?.vendors?.length > 0 ? (
                  vendorData.vendors.map((d) => (
                    <DistributorCard
                      key={d.id}
                      distributor={d}
                      selectDistributorId={handleSelectDistributor}
                      toggleEditDistributorModalOpen={handleOpenEditModal}
                      toggleDeleteDistributorModalOpen={handleDeleteModal}
                      reloadVendors={reloadVendors}
                    />
                  ))
                ) : (
                  <CTABlock
                    header={"You haven't added any distributors"}
                    subheader={
                      "Add distributors for sending orders and building out your inventory"
                    }
                    hideButton
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <EditDistributorModal
        distributor={distributorToEdit}
        toggleEditDistributorModal={handleCloseEditDistributorModal}
        open={editDistributorModalOpen}
        distributorUpdateCallback={distributorUpdateCallback}
      />
      <DeleteDistributorModal
        isOpen={deleteDistributorModalOpen}
        toggleModalOpen={handleDeleteModal}
        distributorId={selectedDistributorId}
        reloadVendors={reloadVendors}
        closeDeleteModal={handleCloseDeleteModal}
        showSuccess={showSuccess}
        showError={showError}
      />
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default Vendors;

import { Box, Grid } from "@mui/material";
import React from "react";
import SpecBackButton from "../../../components/SpecBackButton";
import AddDistributorForm from "./AddDistributorForm";

const AddDistributor = ({
  toggleAddingDistributor,
  establishmentId,
  reloadVendors,
  showSuccess,
  showError,
}) => {
  return (
    <Grid
      container
      direction="column"
      sx={{
        maxWidth: "2440px",
        display: "flex",
        marginLeft: 0,
        padding: 2,
      }}
    >
      <Box sx={{ marginLeft: -2 }}>
        <SpecBackButton
          backText="Your Vendors"
          onClick={() => toggleAddingDistributor(false)}
        />
      </Box>
      <Grid
        container
        direction="row"
        sx={{
          maxWidth: "2440px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 6,
          marginLeft: 2,
        }}
      ></Grid>
      <AddDistributorForm
        toggleAddingDistributor={toggleAddingDistributor}
        establishmentId={establishmentId}
        reloadVendors={reloadVendors}
        showSuccess={showSuccess}
        showError={showError}
      />
    </Grid>
  );
};

export default AddDistributor;

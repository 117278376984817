import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import CreateAutoComplete from "../../../../components/CreateAutoComplete";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import utilFunctions from "../../../../utilities/utilFunctions";

const AddProductCard = ({
  removeProduct,
  productName,
  product,
  updateProduct,
  productFamilies,
  createNewFamily,
  createNewCategory,
  createNewSubcategory,
  clearFamily,
  clearCategory,
  productFamilyMap,
  categoryMap,
  subcategoryMap,
  missingFamiliesOrNameError,
  selectFamily,
  selectCategory,
}) => {
  const [editingName, toggleEditingName] = useState(false);
  const [creatingCategory, toggleCreatingCategory] = useState(false);
  const [creatingFamily, toggleCreatingFamily] = useState(false);
  const [creatingSubcategory, toggleCreatingSubcategory] = useState(false);

  const handleSelectFamily = (val) => {
    selectFamily(product.id, val.id);
  };

  const handleCreateNewFamily = async (newVal) => {
    try {
      toggleCreatingFamily(true);
      const newCat = await createNewFamily(newVal.name);
      handleSelectFamily(newCat);
      toggleCreatingFamily(false);
    } catch (err) {
      console.log(err);
      toggleCreatingFamily(false);
    }
  };

  const handleFamilyClear = () => {
    clearFamily(product.id);
  };

  const handleSelectCategory = (val) => {
    selectCategory(product.id, val.id);
  };

  const handleCreateCategory = async (newVal) => {
    try {
      toggleCreatingCategory(true);
      const newCat = await createNewCategory(
        newVal.name,
        product.productFamilyId
      );
      handleSelectFamily(
        productFamilies.find((f) => f.id === product.productFamilyId)
      );
      handleSelectCategory(newCat);
      toggleCreatingCategory(false);
    } catch (err) {
      console.log(err);
      toggleCreatingCategory(false);
    }
  };

  const handleCategoryClear = () => {
    clearCategory(product.id);
  };

  const handleSelectSubcategory = (val) => {
    updateProduct(product.id, "subcategoryId", val.id);
  };

  const handleCreateSubcategory = async (newVal) => {
    try {
      toggleCreatingSubcategory(true);
      const newCat = await createNewSubcategory(
        newVal.name,
        product.categoryId,
        product.productFamilyId
      );
      handleSelectCategory(
        productFamilies
          .find((f) => f.id === product.productFamilyId)
          .categories.find((c) => c.id === product.categoryId)
      );
      handleSelectSubcategory(newCat);
      toggleCreatingSubcategory(false);
    } catch (err) {
      console.log(err);
      toggleCreatingSubcategory(false);
    }
  };

  const handleSubcategoryClear = () => {
    updateProduct(product.id, "subcategoryId", null);
  };

  const handleUpdateName = (e) => {
    updateProduct(product.id, "name", e.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          padding: 0,
          borderRadius: "8px",
          marginTop: 4,
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain["300"],
        }}
      >
        <CardContent sx={{ width: "100%", height: "108px", padding: 2 }}>
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  sx={{
                    marginLeft: 2,
                    display: "flex",
                    alignItems: "center",
                    width: "85%",
                    maxWidth: "85%",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {editingName ? (
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={productName}
                        onChange={handleUpdateName}
                        onKeyDown={utilFunctions.blurInput}
                        onBlur={() => toggleEditingName(false)}
                        sx={{
                          width: "100%",
                          backgroundColor: (theme) => theme.palette.pure.white,
                          "& legend": { display: "none" },
                          borderRadius: 1,

                          // "& .MuiOutlinedInput-root": {
                          //   background: (theme) => theme.palette.pure.white,
                          //   borderRadius: 1,
                          //   paddingLeft: 3,
                          //   fontWeight: 700,
                          //   fontFamily: "Quicksand",
                          //   width: "100%",
                          // },
                          input: {
                            backgroundColor: (theme) =>
                              theme.palette.pure.white,
                            marginTop: -1,
                            borderRadius: 1,
                          },
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          maxWidth: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography variant="h4" noWrap>
                          {productName ? productName : "Product Name"}
                          {!productName && missingFamiliesOrNameError && (
                            <Typography
                              variant="h5"
                              noWrap
                              sx={{
                                color: (theme) => theme.palette.error[600],
                              }}
                            >
                              Required
                            </Typography>
                          )}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {!editingName && (
                <Grid item sx={{ marginLeft: 2 }}>
                  <IconButton onClick={() => toggleEditingName(true)}>
                    <EditOutlinedIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Grid>
              )}
              <IconButton
                variant="outlined"
                onClick={() => removeProduct(product)}
                sx={{
                  margin: 2,
                  width: "40px",
                  height: "40px",
                }}
              >
                <ClearIcon
                  sx={{ color: (theme) => theme.palette.terrain["900"] }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Grid item xs={4}>
                <CreateAutoComplete
                  options={productFamilies}
                  getOptionLabel={(option) => option.name}
                  value={
                    productFamilyMap[product.productFamilyId] || {
                      id: -1,
                      name: "",
                    }
                  }
                  disabled={creatingFamily}
                  loading={creatingFamily}
                  handleSelect={handleSelectFamily}
                  handleNew={handleCreateNewFamily}
                  handleClear={handleFamilyClear}
                  label="Product Family"
                  modalHeader="Create New Product Family"
                  modalText="Are you sure you want to create a new Product Family? All Product Families can be edited in the Product Library."
                  modalIcon={<LiquorOutlinedIcon sx={{ fontSize: "80px" }} />}
                  error={
                    !productFamilyMap[product.productFamilyId] &&
                    missingFamiliesOrNameError
                  }
                  useCreateModal
                />
              </Grid>
              <Grid item xs={4}>
                <CreateAutoComplete
                  options={
                    productFamilies.find(
                      (x) => x.id === product.productFamilyId
                    )?.categories || []
                  }
                  getOptionLabel={(option) => option.name}
                  value={
                    categoryMap[product.categoryId] || { id: -1, name: "" }
                  }
                  handleSelect={handleSelectCategory}
                  handleNew={handleCreateCategory}
                  handleClear={handleCategoryClear}
                  label="Category"
                  modalHeader="Create New Product Category"
                  modalText="Are you sure you want to create a new Product Category? All Product Categories can be edited in the Product Library."
                  modalIcon={<CategoryOutlinedIcon sx={{ fontSize: "80px" }} />}
                  useCreateModal
                  disabled={!product.productFamilyId || creatingCategory}
                  loading={creatingCategory}
                />
              </Grid>
              <Grid item xs={4}>
                <CreateAutoComplete
                  options={
                    productFamilies
                      .find((x) => x.id === product.productFamilyId)
                      ?.categories?.find((x) => x.id === product.categoryId)
                      ?.subcategories || []
                  }
                  getOptionLabel={(option) => option.name}
                  value={
                    subcategoryMap[product.subcategoryId] || {
                      id: -1,
                      name: "",
                    }
                  }
                  handleSelect={handleSelectSubcategory}
                  handleNew={handleCreateSubcategory}
                  handleClear={handleSubcategoryClear}
                  label="Subcategory"
                  modalHeader="Create New Product Subcategory"
                  modalText="Are you sure you want to create a new Product Subcategory? All Product Subcategories can be edited in the Product Library."
                  modalIcon={<MenuOpenOutlinedIcon sx={{ fontSize: "80px" }} />}
                  useCreateModal
                  disabled={!product.categoryId || creatingSubcategory}
                  loading={creatingSubcategory}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddProductCard;

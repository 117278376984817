import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import RepCard from "./RepCard";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import Store from "../../../Store/Store";

const DistributorCard = ({
  distributor,
  toggleEditDistributorModalOpen,
  selectDistributorId,
  toggleDeleteDistributorModalOpen,
  reloadVendors,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const store = useContext(Store);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditDistributorClick = () => {
    handleClose();
    toggleEditDistributorModalOpen(distributor);
  };

  const handleDeleteDistributorClick = () => {
    selectDistributorId(distributor.id);
    handleClose();
    toggleDeleteDistributorModalOpen(distributor);
  };

  const deleteVendorContactMutation = useMutation(
    inventoryClient.deleteVendorContact,
    {
      onSuccess: () => {
       reloadVendors();
      },
    }
  );

  const deleteVendorContact = async (contactId) => {
    try {
      const archivePayload = {
        establishmentId: store.currentEstablishment?.id,
        contactId: contactId,
        vendorId: distributor.id,
      };

      deleteVendorContactMutation.mutate(archivePayload);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: (theme) => theme.palette.terrain["300"],
          marginBottom: 4,
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Grid item xs={10} sx={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={1}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: { xs: "32px", md: "48px" },
                      height: { xs: "32px", md: "48px" },
                      border: "3px solid",
                      borderColor: (theme) => theme.palette.terrain[600],
                      borderRadius: "150px",
                      overflow: "hidden",
                    }}
                  >
                    <LocalShippingOutlinedIcon
                      sx={{
                        color: (theme) => theme.palette.terrain[600],
                        fontSize: { xs: 14, md: 24 },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    marginLeft: { xs: 4, md: 0 },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      textOverflow="ellipsis"
                      sx={{ fontSize: { xs: 16, md: 20 } }}
                    >
                      {distributor.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                variant="outlined"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  margin: 2,
                  border: "3px solid",
                  width: "40px",
                  height: "40px",
                }}
                onClick={handleClick}
              >
                <MoreHorizIcon
                  sx={{ color: (theme) => theme.palette.terrain["900"] }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent
          sx={{ height: "36px", width: "100%", margin: 0, padding: 0 }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "36px",
              backgroundColor: (theme) => theme.palette.terrain[800],
              color: (theme) => theme.palette.pure.white,
            }}
          >
            <Typography variant="h5" sx={{ marginLeft: 4 }}>
              Contacts
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "36px",
              }}
            >
              {!expanded ? (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none",
                    "&:hover": {
                      background: "none",
                      color: (theme) => theme.palette.terrain[100],
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <KeyboardArrowDownOutlinedIcon />
                </IconButton>
              ) : (
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.pure.white,
                    height: "36px",
                    background: "none",
                    "&:hover": {
                      background: "none",
                      color: (theme) => theme.palette.terrain[100],
                    },
                  }}
                  onClick={handleExpandClick}
                >
                  <ExpandLessOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </CardContent>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              background: (theme) => theme.palette.terrain[50],
              borderTop: "1px solid",
              borderColor: (theme) => theme.palette.terrain[300],
            }}
          >
            {distributor.contacts.map((c) => (
              <RepCard
                contact={c}
                showCtas
                key={c.id}
                deleteVendorContact={deleteVendorContact}
              />
            ))}
          </CardContent>
        </Collapse>
      </Card>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem sx={{ margin: "5px" }} onClick={handleEditDistributorClick}>
          <EditIcon sx={{ marginRight: "5px" }} />
          Edit
        </MenuItem>
        <MenuItem sx={{ margin: "5px" }} onClick={handleDeleteDistributorClick}>
          <DeleteOutlineOutlinedIcon sx={{ marginRight: "5px" }} />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export default DistributorCard;

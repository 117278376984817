import React, { useContext } from "react";
import { modalBoxStyle } from "./styles";
import {
  Button,
  Typography,
  Box,
  Modal,
  CircularProgress,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Store from "../../Store/Store";
import { useMutation } from "react-query";
import inventoryClient from "../../clients/inventoryClient";

const DeleteDistributorModal = ({
  distributorId,
  isOpen,
  toggleModalOpen,
  reloadVendors,
  closeDeleteModal,
  showSuccess,
  showError,
}) => {
  const store = useContext(Store);

  const handleCloseModal = () => {
    closeDeleteModal();
  };

  const archiveVendorMutation = useMutation(inventoryClient.archiveVendor, {
    onSuccess: () => {
      reloadVendors();
      handleCloseModal();
      showSuccess("Distributor archived successfully");
    },
    onError: () => {
      showError("There was an error archiving the distributor.");
    },
  });

  const handleDeleteCallback = async () => {
    const archivePayload = {
      establishmentId: store.currentEstablishment?.id,
      vendorId: distributorId,
    };

    archiveVendorMutation.mutate(archivePayload);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {archiveVendorMutation.isError ? (
          <Typography
            variant="body1"
            sx={{ color: (theme) => theme.palette.error["500"] }}
          >
            There was an error deleteing the distributor. Please try again or
            contact support if the issue persists.
          </Typography>
        ) : null}
        <Box sx={modalBoxStyle}>
          {archiveVendorMutation.isLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Box>
                <Box
                  sx={{
                    marginBottom: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <DeleteOutlineOutlinedIcon sx={{ fontSize: "60px" }} />
                </Box>
                <Box
                  sx={{
                    marginBottom: "1rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h2">Archive Distributor</Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    {"Are you sure you want to archive this distributor? "}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", marginTop: 1 }}
                  >
                    {
                      "By selecting yes, items that are linked to this distributor will still be present but once cleared they will be lost."
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{ borderRadius: 2 }}
                    variant="outlined"
                    onClick={() => {
                      toggleModalOpen();
                    }}
                  >
                    <Typography variant="smallButton">No, Cancel</Typography>
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ borderRadius: 2, marginLeft: 2 }}
                    onClick={handleDeleteCallback}
                  >
                    <Typography variant="smallButton">Yes, Delete</Typography>
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </>
    </Modal>
  );
};

export default DeleteDistributorModal;

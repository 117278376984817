import axios from "axios";
import { getAccessToken, getUserIdFromToken } from "./identityClient";
import ReactGA from "react-ga";

export const establishmentsClientConfig = {
  baseURL: "",
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST, GET, PUT, OPTIONS, DELETE",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type",
    Accept: "application/json",
  },
  maxRedirects: 0,
};

const establishmentsClient = {
  getEstablishmentStaffMembers: async ({ queryKey }) => {
    // eslint-disable-next-line no-unused-vars
    const [_key, { establishmentId }] = queryKey;
    const token = getAccessToken();
    establishmentsClientConfig.headers.Authorization = token;
    const response = await axios.get(
      `/Establishments/${establishmentId}/establishmentmemberships`,
      establishmentsClientConfig
    );

    return response.data;
  },
};

export async function createEstablishment(establishment) {
  const uId = getUserIdFromToken();
  ReactGA.event({
    category: "Establishment",
    action: "Create",
    label: `user:${uId}`,
  });
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.post(
      "/Establishments",
      establishment,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishmentMessages(establishmentId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/messages/GetEstablishmentMessages/${establishmentId}`,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Establishment",
      action: "GetMessages",
      label: establishmentId,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getMoreMessages(establishmentId, paginationToken) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/messages/GetEstablishmentMessages/${establishmentId}?paginationToken=${paginationToken}`,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Establishment",
      action: "GetMoreMessages",
      label: establishmentId,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function sendEstablishmentMessage(message) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/messages/SendStaff`,
      message,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Establishment",
      action: "SendMessages",
      label: message.establishmentId,
    });
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function deleteMessage(messageId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.delete(
      `/Messages/deletemessage?messageId=${messageId}`,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Establishment",
      action: "DeleteMessage",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateEstablishment(establishment) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.put(
      "/establishments",
      establishment,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Establishment",
      action: "Update",
      label: establishment.id,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getUserEstablishments() {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      "/My/Establishments",
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getUserAdminEstablishments() {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      "/My/Establishments/Admin",
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishmentCategories(establishmentId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/establishments/${establishmentId}/categories`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getCategoryById(categoryId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/categories/${categoryId}`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getFlattenedEstablishmentCategories(establishmentId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/establishments/${establishmentId}/flatcategories`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishment(establishmentId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/establishments/${establishmentId}/`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishmentReactQuery({ queryKey }) {
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId }] = queryKey;
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/establishments/${establishmentId}/`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function joinEstablishment(code) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      "/Memberships/Join",
      { inviteCode: code },
      establishmentsClientConfig
    );
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function addRecipe(recipe) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      "/Categories/AddRecipe",
      recipe,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Recipe",
      action: "Creation",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function addWine(wine) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post("/Categories/AddWine", wine, establishmentsClientConfig);
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Wine",
      action: "Creation",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function addBeer(beer) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post("/Categories/AddBeer", beer, establishmentsClientConfig);
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Beer",
      action: "Creation",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function addCategory(category) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    const response = await axios.post(
      "/Categories",
      category,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Category",
      action: "Creation",
      label: `user:${uId}`,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateRecipe(recipe) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/Items/updaterecipe/${recipe.id}`,
      recipe,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Recipe",
      action: "Update",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateBeer(beer) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/Items/updatebeer/${beer.id}`,
      beer,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Beer",
      action: "Update",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateWine(wine) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/Items/updatewine/${wine.id}`,
      wine,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Wine",
      action: "Update",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateCategory(category) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    const response = await axios.post(
      `/Categories/update/${category.id}`,
      category,
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Category",
      action: "Update",
      label: `user:${uId}`,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishmentMemberships(establishmentid) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    let response = await axios.get(
      `/Establishments/${establishmentid}/establishmentmemberships`,
      establishmentsClientConfig
    );
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function sendEstablishmentInvite(
  establishmentid,
  role,
  deliveryMethod,
  deliveryAddress
) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/Memberships/Invite`,
      {
        establishmentid: establishmentid,
        role: role,
        deliveryMethod: deliveryMethod,
        deliveryAddress: deliveryAddress,
      },
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Admin",
      action: "Send Staff Invite",
      label: establishmentid,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function removeEmployee(establishmentId, userId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/memberships/${establishmentId}/revoke/${userId}`,
      null,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Admin",
      action: "Remove Staff Member",
      label: establishmentId,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateStaffRole(newRole, establishmentId, userId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/memberships/${establishmentId}/updaterole/${userId}`,
      {
        newRole: newRole,
      },
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Establishment",
      action: "Update Staff Role",
      label: establishmentId,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}
export async function linkLegacyAccount(legacyUserId, legacyAccountToken) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/legacy`,
      { firebaseUserId: legacyUserId, legacyAccountToken },
      establishmentsClientConfig
    );
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function updateCategoryItem(item) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/items/update${item.type.toLowerCase()}/${item.id}`,
      item,
      establishmentsClientConfig
    );
    ReactGA.event({
      category: "Item",
      action: "Update",
      label: item.type.toLowerCase(),
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function deleteItem(itemId, itemType) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  try {
    await axios.post(
      `/items/delete${itemType.toLowerCase()}/${itemId}`,
      {},
      establishmentsClientConfig
    );
    const uId = getUserIdFromToken();
    ReactGA.event({
      category: "Item",
      action: "Delete",
      label: `user:${uId}`,
    });
    return;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export async function getEstablishmentSubscription({ queryKey }) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  // eslint-disable-next-line no-unused-vars
  const [_key, { establishmentId }] = queryKey;
  const response = await axios.get(
    `/establishments/${establishmentId}/subscription-status`,
    establishmentsClientConfig
  );

  return response.data;
}

export async function getMyMemberships() {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  const response = await axios.get(
    `/my/memberships`,
    establishmentsClientConfig
  );

  return response.data;
}

export async function createStripePortalSession(establishmentId) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  var response = await axios.post(
    "subscription/customer-portal",
    {
      establishmentId,
    },
    establishmentsClientConfig
  );

  if (response.status === 200) {
    // Extract the URL from the 'Location' header
    const location = response.data.sessionUrl;
    // Redirect to the URL (using window.location or your preferred method)
    window.location.href = location;
  } else {
    // Handle other responses
    return response.data;
  }
}

export async function createStripeCheckoutSession(
  establishmentId,
  billingInterval
) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  const response = await axios.post(
    "subscription/create-checkout-session",
    {
      establishmentId,
      billingInterval,
    },
    establishmentsClientConfig
  );

  if (response.status === 200) {
    // Extract the URL from the 'Location' header
    const location = response.data.sessionUrl;
    // Redirect to the URL (using window.location or your preferred method)
    window.location.href = location;
  } else {
    // Handle other responses
    return response.data;
  }
}

export async function transferEstablishmentOwnership({
  establishmentId,
  userId,
}) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  var response = await axios.post(
    `/memberships/${establishmentId}/make-owner/${userId}`,
    {},
    establishmentsClientConfig
  );

  return response.data;
}

export async function migrateForInventory({ establishmentId }) {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  var response = await axios.post(
    `/establishments/${establishmentId}/migrate-for-inventory`,
    {},
    establishmentsClientConfig
  );

  return response.data;
}

export async function checkAccountDeletable() {
  establishmentsClientConfig.headers.Authorization = getAccessToken();
  let response = await axios.get(
    "/My/account-deletable-check",
    establishmentsClientConfig
  );
  return response.data;
}

export async function createEstablishmentReactQuery(payload) {
  const token = getAccessToken();
  establishmentsClientConfig.headers.Authorization = token;
  let response = await axios.post(
    "/Establishments",
    payload,
    establishmentsClientConfig
  );
  return response;
}

export default establishmentsClient;

import {
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import { useMutation } from "react-query";
import inventoryClient from "../../../clients/inventoryClient";
import { cloneDeep } from "lodash";
import Store from "../../../Store/Store";
import useVendorCurrency from "../../../hooks/useVendorCurrency";
import utilFunctions from "../../../utilities/utilFunctions";
import { recipeIngredientTypes } from "../../../clients/inventoryObjects";
import DropDownBadgeSelector from "./DropDownBadgeSelector";
import ProductSelectionToggle from "./ProductSelectionToggle";
import MenuDisplayNameToggle from "./MenuDisplayNameToggle";
import QuantityToggle from "./QuantityToggle";
// import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import CalculatorIcon from "../../../components/CustomIcons/CaluclatorIcon";
import CalculatorIconCrossed from "../../../components/CustomIcons/CaluclatorIconCrossed";
import MissingDataPointsTooltip from "../../../components/MissingDataPointsTooltip";
import useEstablishmentSettings from "../../../hooks/useEstablishmentSettings";

const RecipeIngredientRow = ({
  openQuantityModal,
  ingredient,
  productLibrary,
  productLibraryMap,
  updateIngredientCallback,
  deleteIngredientCallback,
  tableCellStyle,
  establishmentRecipes,
  openCreateModal,
}) => {
  const [productSearchOptions, setProductSearchOptions] = useState([]);
  const [ingredientCopy, setIngredientCopy] = useState({});
  const store = useContext(Store);
  const [currentTextInput, setCurrentTextInput] = useState("");
  const { data: settings } = useEstablishmentSettings(
    store.currentEstablishment?.id
  );
  const { locale, currency, currencySymbol } = useVendorCurrency(
    settings?.inventorySettings?.currency
  );

  useEffect(() => {
    setIngredientCopy(cloneDeep(ingredient));
    const optionsForIngs = [];
    productLibrary?.forEach((x) => {
      // This isProduct property setting is important. We add a temp property to make it easier to
      // check the difference in options when searching. It also then is used when making updates
      // to the product to determine which id to set
      x.isProduct = true;
      optionsForIngs.push(x);
    });
    const activeRecipes = establishmentRecipes.filter((x) => x.isActive);
    setProductSearchOptions([...optionsForIngs, ...activeRecipes]);
  }, [ingredient, productLibrary]);

  const ingredientMutation = useMutation(inventoryClient.patchIngredient, {
    onSuccess: (result) => {
      updateIngredientCallback(result.ingredient);
    },
  });

  const deleteIngredientMutation = useMutation(
    inventoryClient.removeIngredient,
    {
      onSuccess: () => {
        deleteIngredientCallback();
      },
    }
  );

  const handlePropertyUpdate = async (property, newValue) => {
    const payload = {
      establishmentId: store.currentEstablishment?.id,
      recipeId: ingredient.recipeId,
      ingredientId: ingredient.id,
    };

    payload[`${property}Update`] = { value: newValue };
    await ingredientMutation.mutateAsync(payload);
  };

  const handleClear = async () => {
    const updatePayload = {
      productUpdate: {
        value: null,
      },
      typeUpdate: {
        value: recipeIngredientTypes.ProductFormat,
      },
      establishmentId: store.currentEstablishment?.id,
      recipeId: ingredient.recipeId,
      ingredientId: ingredient.id,
    };

    await ingredientMutation.mutateAsync(updatePayload);
  };

  const handleSelectIngredient = (ing) => {
    const updatePayload = {
      establishmentId: store.currentEstablishment?.id,
      recipeId: ingredient.recipeId,
      ingredientId: ingredient.id,
    };
    if (ing.isProduct) {
      updatePayload.productUpdate = {
        value: ing.id,
      };
    } else {
      updatePayload.recipeIngredientReferenceUpdate = {
        value: ing.id,
      };
    }

    ingredientMutation.mutate(updatePayload);
  };

  const handleOnIngredient = (newValue) => {
    if (newValue.id === -1) {
      handlePropertyUpdate("type", recipeIngredientTypes.OpenText);
    } 
    else if (newValue.id === -2) {
      openCreateModal(ingredient.id, currentTextInput);
      setCurrentTextInput("");
    }
    else {
      handleSelectIngredient(newValue);
    }
  };

  const handleDeleteIngredient = () => {
    const updatePayload = {
      establishmentId: store.currentEstablishment?.id,
      ingredientId: ingredient.id,
    };

    deleteIngredientMutation.mutate(updatePayload);
  };

  const renderFormatDisplay = (productFormat) => {
    return utilFunctions.extractFormatSizingName(productFormat);
  };

  const isNoProductSelected =
    (ingredientCopy.productId === null ||
      ingredientCopy.productId === undefined) &&
    (ingredientCopy.recipeIngredientReferenceId === null ||
      ingredientCopy.recipeIngredientReferenceId === undefined)
      ? true
      : false;

  return (
    <>
      <TableRow>
        {/* DRAG ICON COMPONENT */}
        {/* <TableCell
          sx={{
            borderLeft: "none",
            borderBottom: "1px solid",
            borderColor: (theme) => theme.palette.terrain[400],
            backgroundColor: (theme) => theme.palette.pure.white,
            padding: 0,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <DragHandleOutlinedIcon
              sx={{ color: (theme) => theme.palette.primary[800] }}
            />
          </Box>
        </TableCell> */}
        <TableCell sx={[tableCellStyle, { borderLeft: "none" }]}>
          <QuantityToggle
            ingredient={ingredientCopy}
            openQuantityModal={openQuantityModal}
          />
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <ProductSelectionToggle
            selected={
              ingredientCopy.type === recipeIngredientTypes.Recipe
                ? ingredientCopy.recipeIngredientReference
                : ingredientCopy.product
            }
            selectedId={
              ingredientCopy?.type === recipeIngredientTypes.Recipe
                ? ingredientCopy.recipeIngredientReferenceId
                : ingredientCopy.productId
            }
            ingredientType={ingredientCopy.type}
            options={productSearchOptions}
            ingredient={ingredientCopy}
            clearProduct={handleClear}
            selectCallback={handleOnIngredient}
            setCurrentTextInput={setCurrentTextInput}
          />
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <MenuDisplayNameToggle
            ingredientDisplayName={ingredientCopy.menuDisplayName}
            isNotAvailable={
              isNoProductSelected &&
              ingredientCopy.type !== recipeIngredientTypes.OpenText
                ? true
                : false
            }
            updateDisplayName={(newName) =>
              handlePropertyUpdate("menuDisplayName", newName)
            }
          />
        </TableCell>
        <TableCell sx={tableCellStyle}>
          {/* The DropDownBadgeSelector has its grid wrapper embedded to dynamically update style based on when it is in an edit mode or not */}
          <DropDownBadgeSelector
            options={
              productLibraryMap[ingredientCopy.productId]?.productFormats
            }
            labelText="Product Format"
            isNotAvailable={
              isNoProductSelected ||
              ingredientCopy.type !== recipeIngredientTypes.ProductFormat
            }
            selectedId={ingredientCopy.productFormatId}
            selected={productLibraryMap[
              ingredientCopy.productId
            ]?.productFormats?.find(
              (x) => x.id === ingredientCopy.productFormatId
            )}
            extractDisplayFunction={renderFormatDisplay}
            menuItemRenderFunction={renderFormatDisplay}
            selectCallback={async (v) =>
              await handlePropertyUpdate("productFormat", v)
            }
          />
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <DropDownBadgeSelector
            options={
              productLibraryMap[ingredientCopy.productId]?.productFormats.find(
                (f) => f.id === ingredientCopy.productFormatId
              )?.purchaseDetails
            }
            labelText="Purchase Unit"
            isNotAvailable={
              isNoProductSelected ||
              ingredientCopy.type !== recipeIngredientTypes.ProductFormat
            }
            selectedId={ingredientCopy.purchaseUnitId}
            extractDisplayFunction={(obj, id, options) =>
              options?.find((x) => x.id === id)?.name
            }
            menuItemRenderFunction={(i) => i.name}
            selectCallback={async (v) =>
              await handlePropertyUpdate("purchaseUnit", v)
            }
          />
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Checkbox
              icon={<VisibilityOffOutlinedIcon />}
              checkedIcon={<VisibilityOutlinedIcon />}
              checked={ingredient.displayInTraining}
              onChange={() =>
                handlePropertyUpdate(
                  "displayInTraining",
                  !ingredient.displayInTraining
                )
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Checkbox
              icon={
                // <CalculateOutlinedIcon
                //   sx={{ color: (theme) => theme.palette.terrain[600] }}
                // />
                <CalculatorIconCrossed
                  sx={{
                    color: (theme) => theme.palette.terrain[400],
                    fontSize: 20,
                    marginRight: 1,
                  }}
                />
              }
              checkedIcon={
                <CalculatorIcon
                  sx={{
                    color: (theme) => theme.palette.primary[800],
                    fontSize: 20,
                  }}
                />
              }
              checked={ingredient.includeInCosting}
              onChange={() =>
                handlePropertyUpdate(
                  "includeInCosting",
                  !ingredient.includeInCosting
                )
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Checkbox
              sx={{ color: (theme) => theme.palette.primary[800] }}
              checked={ingredient.isGarnish}
              onChange={() =>
                handlePropertyUpdate("isGarnish", !ingredient.isGarnish)
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Box sx={{ paddingLeft: 2 }}>
            <Typography variant="body1">
              {ingredientCopy?.type === recipeIngredientTypes.Recipe
                ? currencySymbol +
                  utilFunctions.convertCentsToLargerFormatCurrency(
                    ingredientCopy.laborCost?.result?.amount,
                    locale,
                    currency
                  )
                : "-"}
            </Typography>
          </Box>
        </TableCell>
        <TableCell sx={tableCellStyle}>
          <Box sx={{ paddingLeft: 2 }}>
            {ingredientCopy?.ingredientCost?.isValid === false && (
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <MissingDataPointsTooltip
                  missingValues={
                    ingredientCopy?.ingredientCost?.validationErrors
                  }
                />
              </Box>
            )}
            {ingredientCopy.ingredientCost?.isValid && (
              <Typography variant="body1">
                {" "}
                {currencySymbol}
                {utilFunctions.convertCentsToLargerFormatCurrency(
                  ingredientCopy.ingredientCost?.result?.amount || 0,
                  locale,
                  currency
                )}
              </Typography>
            )}
          </Box>
        </TableCell>
        <TableCell sx={[tableCellStyle, { borderRight: "none" }]}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <IconButton
              sx={{
                border: "2px solid",
                borderColor: (theme) => theme.palette.primary[800],
                height: "40px",
                width: "40px",
              }}
              onClick={handleDeleteIngredient}
            >
              <DeleteOutlinedIcon
                sx={{ color: (theme) => theme.palette.primary[800] }}
              />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RecipeIngredientRow;

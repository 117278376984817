import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import TabPanel from "../../components/TabPanel";
import AddProductForm from "./components/AddProductForm";
import ProductTabNav from "./components/ProducTabNav";
import inventoryClient from "../../clients/inventoryClient";
import Store from "../../Store/Store";
import ProductFormatTab from "./components/ProductFormatTab";
import TrainingDetailsTab from "./components/TrainingDetailsTab";
import AllProductsTab from "./components/AllProductsTab";
import PurchaseUnitTab from "./components/PurchaseUnitTab";
import SpecSpinner from "../../components/SpecSpinner";
import SpecBackButton from "../../components/SpecBackButton";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { debounce } from "lodash";
import useEstablishmentUoMs from "../../hooks/useEstablishmentUoMs";
import { useParams } from "react-router-dom";
import useVendors from "../../hooks/useVendors";
import useEstablishmentExpenseGroups from "../../hooks/useEstablishmentExpenseGroups";
import CreateExpenseGroupModal from "../../components/Modals/CreateExpenseGroupModal";
import CreateExpenseSubgroupModal from "../../components/Modals/CreateExpenseSubgroupModal";
import useProductLibraryPaginated from "../../hooks/useProductLibraryPaginated";
import useEstablishmentProductFamilyGroups from "../../hooks/useEstablishmentProductFamilyGroups";
import { useQueryClient } from "react-query";
import ProductFiltersModal from "../../components/Modals/ProductFiltersModal";
import FilterListIcon from "@mui/icons-material/FilterList";
import inventoryObjects from "../../clients/inventoryObjects";
import useProductLibraryPaginatedSearch from "../../hooks/usePaginatedProductLibrarySearch";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import { useDebounce } from "../../hooks/useDebounce";

const ProductLibrary = () => {
  const { establishmentid } = useParams();
  const store = useContext(Store);
  const [error, toggleError] = useState(false);
  const [addingProduct, toggleAddingProduct] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [alertSuccess, setAlertSuccess] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [isProductFilterModalOpen, toggleIsProductFilterModalOpen] =
    useState(false);
  const [isExpenseGroupModalOpen, toggleIsExpenseGroupModalOpen] =
    useState(false);
  const [isExpenseSubgroupModalOpen, toggleIsExpenseSubgroupModalOpen] =
    useState(false);
  const [expenseGroupForSubgroupEdit, setExpenseGroupForSubgroupEdit] =
    useState({});
  const [localSearchContent, setLocalSearchContent] = useState("");
  const debouncedSearchContent = useDebounce(localSearchContent, 600);
  const {
    data: unitOfMeasurementData,
    isLoading: isLoadingUnitsOfMeasurement,
  } = useEstablishmentUoMs(establishmentid);
  const [{ data: vendorData, isLoading: isLoadingVendors }] =
    useVendors(establishmentid);
  const { data: expenseGroupData, isLoading: isLoadingExpenseData } =
    useEstablishmentExpenseGroups(establishmentid);
  const {
    data: paginatedProductData,
    isLoading: isLoadingPaginatedProducts,
    // isFetching: isFetchingPaginated,
  } = useProductLibraryPaginated(
    establishmentid,
    store?.productLibraryPage,
    store?.productLibrarySortFilters,
    store?.productLibrarySortAscending,
    store?.productLibraryPropertyForSort
  );
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useEstablishmentSubscription(establishmentid);
  const { data: paginatedSearchData, isLoading: isLoadingPaginatedSearchData } =
    useProductLibraryPaginatedSearch(
      establishmentid,
      debouncedSearchContent,
      store?.productLibraryPage,
      store?.productLibrarySortFilters,
      store?.productLibrarySortAscending,
      store?.productLibraryPropertyForSort
    );
  const { data: familyData, isLoading: isLoadingFamilyData } =
    useEstablishmentProductFamilyGroups(establishmentid);

  useEffect(() => {
    setLocalSearchContent(store?.productLibrarySearchText);
  }, [store?.productLibrarySearchText]);

  const queryClient = useQueryClient();
  const productLibraryRef = useRef();

  const handleChange = (event, newValue) => {
    store.setSelectedProductLibraryPanel(newValue);
  };

  const deleteCallback = () => {
    queryClient.invalidateQueries({
      queryKey: ["inventory-paginated"],
    });
    queryClient.refetchQueries({
      queryKey: ["inventory-paginated"],
      type: "all",
    });
    queryClient.invalidateQueries({
      queryKey: ["inventory"],
    });
    queryClient.refetchQueries({
      queryKey: ["inventory"],
      type: "all",
    });
  };

  const handleAddProductFamily = async (name) => {
    const response = await inventoryClient.addProductFamily(
      name,
      store.currentEstablishment?.id
    );
    const newFamily = response.data.productFamily;
    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newFamily;
  };

  const handleAddCategory = async (name, productFamilyId) => {
    const response = await inventoryClient.addCategory(
      name,
      productFamilyId,
      store.currentEstablishment?.id
    );
    const newCategory = response.data.category;
    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newCategory;
  };

  const handleAddSubcategory = async (name, categoryId) => {
    const response = await inventoryClient.addSubcategory(
      name,
      categoryId,
      store.currentEstablishment?.id
    );
    const newSubcategory = response.data.subcategory;

    queryClient.invalidateQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
    });
    await queryClient.refetchQueries({
      queryKey: ["productFamilyGroups", { establishmentId: establishmentid }],
      type: "all",
    });
    return newSubcategory;
  };

  const handleAddProductComplete = async () => {
    toggleAddingProduct(false);
    store.appendInvalidateQueriesQueue("inventory");
    queryClient.invalidateQueries({
      queryKey: ["inventory-paginated"],
    });
    queryClient.refetchQueries({
      queryKey: ["inventory-paginated"],
      type: "all",
    });
    setAlertSuccess("Success! Product has been added!");
  };

  const handleProductUpdate = (product) => {
    if (!store?.productLibrarySearchText) {
      const state = queryClient.getQueryState({
        queryKey: [
          "inventory-paginated",
          {
            establishmentId: establishmentid,
            page: store?.productLibraryPage,
            filters: store?.productLibrarySortFilters,
            sortAscending: store?.productLibrarySortAscending,
            propertyForSort: store?.productLibraryPropertyForSort,
          },
        ],
      });

      const newState = state.data;

      newState.products = newState?.products?.map((x) => {
        if (x.id === product.id) {
          return product;
        }

        return x;
      });

      queryClient.setQueryData(
        [
          "inventory-paginated",
          {
            establishmentId: establishmentid,
            page: store?.productLibraryPage,
            filters: store?.productLibrarySortFilters,
            sortAscending: store?.productLibrarySortAscending,
            propertyForSort: store?.productLibraryPropertyForSort,
          },
        ],
        newState
      );
    } else {
      const state = queryClient.getQueryState({
        queryKey: [
          "paginated-product-search",
          {
            establishmentId: establishmentid,
            page: store?.productLibraryPage,
            searchString: store?.productLibrarySearchText,
            filters: store?.productLibrarySortFilters,
            sortAscending: store?.productLibrarySortAscending,
            propertyForSort: store?.productLibraryPropertyForSort,
          },
        ],
      });
      const newState = state.data;

      newState.products = newState?.products?.map((x) => {
        if (x.id === product.id) {
          return product;
        }

        return x;
      });

      queryClient.setQueryData(
        [
          "paginated-product-search",
          {
            establishmentId: establishmentid,
            page: store?.productLibraryPage,
            searchString: store?.productLibrarySearchText,
            filters: store?.productLibrarySortFilters,
            sortAscending: store?.productLibrarySortAscending,
            propertyForSort: store?.productLibraryPropertyForSort,
          },
        ],
        newState
      );
    }

    store.appendInvalidateQueriesQueue("inventory");
  };

  const handleUpdateExpandedParent = (productId, expandedState) => {
    const newState = inventory.map((x) => {
      if (x.id === productId) {
        return Object.assign({}, x, { expanded: expandedState });
      }

      return x;
    });

    setInventory(newState);
  };

  const handleSearch = (e) => {
    if (!e.target.value) {
      queryClient.invalidateQueries({
        queryKey: ["inventory-paginated"],
      });
      queryClient.refetchQueries({
        queryKey: ["inventory-paginated"],
        type: "all",
      });
    }
    store?.setProductLibraryPage(1);
    store.setProductLibrarySearchText(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearch, 300);

  const handleSearchChange = (e) => {
    debouncedSearchHandler.cancel();
    debouncedSearchHandler(e);
    setLocalSearchContent(e.target.value);
  };

  const handleError = () => {
    toggleError(true);
  };

  const handleOpenExpenseGroupEdit = () => {
    toggleIsExpenseGroupModalOpen(true);
  };

  const handleOpenExpenseSubgroupEdit = (expenseGroupId) => {
    setExpenseGroupForSubgroupEdit(
      expenseGroupData.expenseGroups.find((x) => x.id === expenseGroupId)
    );
    toggleIsExpenseSubgroupModalOpen(true);
  };
  const handlePageSet = (_, page) => {
    store?.setProductLibraryPage(page);
    productLibraryRef?.current?.scrollIntoView();
  };

  const handleCloseProductFilterModal = () => {
    toggleIsProductFilterModalOpen(false);
  };

  const handleOpenProductFilterModal = () => {
    toggleIsProductFilterModalOpen(true);
  };

  const handleSetFilters = (filters) => {
    store?.setProductLibraryPage(1);
    store?.setProductLibrarySortFilters(filters);
    handleCloseProductFilterModal();
  };

  const handleSetSortOptions = (e) => {
    const idChain = e.target.id.split(",");
    store?.setProductLibraryPage(1);
    store?.setProductLibraryPropertyForSort(
      inventoryObjects.productSortProperties[idChain[0]]
    );
    store?.toggleProductLibrarySortAscending(
      idChain[1] === "asc" ? true : false
    );
    handleCloseSortMenu();
  };

  const handleRefetchCurrentPageProducts = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "inventory-paginated",
        {
          establishmentId: establishmentid,
          page: store?.productLibraryPage,
          filters: store?.productLibrarySortFilters,
          sortAscending: store?.productLibrarySortAscending,
          propertyForSort: store?.productLibraryPropertyForSort,
        },
      ],
    });
    queryClient.refetchQueries({
      queryKey: [
        "inventory-paginated",
        {
          establishmentId: establishmentid,
          page: store?.productLibraryPage,
          filters: store?.productLibrarySortFilters,
          sortAscending: store?.productLibrarySortAscending,
          propertyForSort: store?.productLibraryPropertyForSort,
        },
      ],
      type: "all",
    });
  };

  const handleCloseSortMenu = () => {
    setSortMenuAnchor(null);
  };

  const handleOpenSortMenu = (e) => {
    setSortMenuAnchor(e.currentTarget);
  };

  const handleToggleAddingProduct = () => {
    store?.setProductLibrarySearchText("");
    setLocalSearchContent("");
    toggleAddingProduct(true);
  };

  const handleBackFromAdding = () => {
    toggleAddingProduct(false);
  };

  const countFilters = () => {
    let count = 0;

    store?.productLibrarySortFilters?.familyFilters?.forEach((family) => {
      count += 1;
      family.categoryFilters.forEach((category) => {
        count += 1;
        category.subcategoryFilters.forEach(() => {
          count += 1;
        });
      });
    });

    return count;
  };

  const appliedFilterCount = countFilters() || 0;

  if (
    isLoadingUnitsOfMeasurement ||
    isLoadingVendors ||
    isLoadingExpenseData ||
    isLoadingFamilyData ||
    isLoadingPaginatedProducts ||
    isLoadingSubscription
  ) {
    return <SpecSpinner open={true} text="Loading Product Library Data..." />;
  }

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "2440px",
          width: "100%",
          padding: 4,
          marginTop: 6,
          height: "100%",
        }}
      >
        {error && (
          <Typography variant="h6" sx={{ color: "red" }}>
            There was an error fetching product library data, please refresh
          </Typography>
        )}

        {addingProduct ? (
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box>
              <SpecBackButton
                backText="Product Library"
                onClick={handleBackFromAdding}
              />
            </Box>
            <AddProductForm
              productFamilies={familyData?.productFamilies}
              addProductFamily={handleAddProductFamily}
              addCategory={handleAddCategory}
              addSubcategory={handleAddSubcategory}
              distributors={vendorData?.vendors}
              unitsOfMeasurement={unitOfMeasurementData.unitsOfMeasurement}
              productFamilyMap={familyData?.familyMap}
              categoryMap={familyData?.categoryMap}
              subcategoryMap={familyData?.subcategoryMap}
              handleAddProductComplete={handleAddProductComplete}
            />
          </Box>
        ) : (
          <Grid container direction="column">
            <Grid
              container
              sx={{
                maxWidth: "2440px",
                marginBottom: 6,
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "40px",
                minWidth: "100%",
              }}
              xs={2}
            >
              <Grid item xs={9}>
                <Typography variant="h2" ref={productLibraryRef}>
                  Product Library
                </Typography>
              </Grid>
              {/* BUTTONS */}
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 2,
                    padding: 4,
                    height: "40px",
                  }}
                  onClick={handleToggleAddingProduct}
                >
                  <AddOutlinedIcon sx={{ marginRight: 1 }} />
                  <Typography
                    variant="largeButton"
                    sx={{
                      fontSize: { xs: 10, md: 16 },
                      lineHeight: { xs: 1, md: 2 },
                    }}
                  >
                    Add Products
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              sx={{
                minWidth: "100%",
                maxWidth: "2440px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="column"
                  sx={{
                    display: "flex",
                    position: "sticky",
                    top: { xs: 74, md: 64 },
                    zIndex: 4,
                    backgroundColor: (theme) => theme.palette.terrain[50],
                  }}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction="row"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                        marginTop: 2,
                      }}
                    >
                      {/* SEARCH BAR */}
                      <Grid item xs={6} md={8}>
                        <TextField
                          variant="outlined"
                          size="small"
                          fullWidth
                          autoComplete="off"
                          id="productSearch"
                          value={localSearchContent}
                          onChange={handleSearchChange}
                          inputProps={{
                            sx: {
                              marginBottom: 1,
                              marginRight: 1,
                            },
                          }}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.pure.white,
                            borderColor: (theme) => theme.palette.terrain[400],
                            // width: "340px",
                            borderRadius: 1,
                          }}
                          label={
                            <>
                              <SearchOutlinedIcon
                                style={{ paddingRight: "5px" }}
                              />
                              Search for products...
                            </>
                          }
                        />
                      </Grid>
                      {/* FILTER BUTTONS */}
                      <Grid
                        item
                        xs={6}
                        md={4}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        {appliedFilterCount > 0 ? (
                          <Badge
                            color="primary"
                            badgeContent={appliedFilterCount}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{ marginRight: 1, position: "relative" }}
                              onClick={handleOpenProductFilterModal}
                            >
                              <TuneOutlinedIcon
                                sx={{ fontSize: 18, marginRight: 2 }}
                              />
                              <Typography variant="largeButton">
                                Filters
                              </Typography>
                            </Button>
                          </Badge>
                        ) : (
                          <Button
                            variant="outlined"
                            sx={{ marginRight: 1, marginLeft: 1 }}
                            onClick={handleOpenProductFilterModal}
                          >
                            <TuneOutlinedIcon
                              sx={{ fontSize: 18, marginRight: 2 }}
                            />
                            <Typography variant="largeButton">
                              Filters
                            </Typography>
                          </Button>
                        )}
                        <Button
                          variant="outlined"
                          // sx={{ marginRight: 1 }}
                          onClick={handleOpenSortMenu}
                        >
                          <FilterListIcon
                            sx={{ fontSize: 18, marginRight: 2 }}
                          />
                          <Typography variant="largeButton">Sort</Typography>
                        </Button>
                        <Menu
                          anchorEl={sortMenuAnchor}
                          open={!!sortMenuAnchor}
                          onClose={handleCloseSortMenu}
                        >
                          <MenuItem
                            sx={{ margin: "5px" }}
                            selected={
                              store?.productLibrarySortAscending &&
                              store?.productLibraryPropertyForSort ===
                                inventoryObjects.productSortProperties.name
                            }
                            id="name,asc"
                            onClick={handleSetSortOptions}
                          >
                            Name A-Z
                          </MenuItem>
                          <MenuItem
                            sx={{ margin: "5px" }}
                            id="name,desc"
                            selected={
                              !store?.productLibrarySortAscending &&
                              store?.productLibraryPropertyForSort ===
                                inventoryObjects.productSortProperties.name
                            }
                            onClick={handleSetSortOptions}
                          >
                            Name Z-A
                          </MenuItem>
                          <MenuItem
                            sx={{ margin: "5px" }}
                            id="createdDate,desc"
                            selected={
                              !store?.productLibrarySortAscending &&
                              store?.productLibraryPropertyForSort ===
                                inventoryObjects.productSortProperties
                                  .createdDate
                            }
                            onClick={handleSetSortOptions}
                          >
                            Newest First
                          </MenuItem>
                          <MenuItem
                            sx={{ margin: "5px" }}
                            id="createdDate,asc"
                            selected={
                              store?.productLibrarySortAscending &&
                              store?.productLibraryPropertyForSort ===
                                inventoryObjects.productSortProperties
                                  .createdDate
                            }
                            onClick={handleSetSortOptions}
                          >
                            Oldest First
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    sx={{
                      marginBottom: 6,
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "2440px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Box sx={{ marginTop: 6, marginLeft: 0 }}>
                        <ProductTabNav
                          currentTab={store?.selectedProductLibraryPanel}
                          handleChange={handleChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {isLoadingPaginatedSearchData ? (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Grid
                    container
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                      padding: 0,
                      height: "100%",
                    }}
                  >
                    {/* TABLE COMPONENT */}
                    <Box
                      sx={{
                        zIndex: 0,
                        width: "100%",
                        overflowX: "scroll",
                        height: "100%",
                      }}
                    >
                      <TabPanel
                        value={store.selectedProductLibraryPanel}
                        index={0}
                        sx={{ height: "100%" }}
                      >
                        <AllProductsTab
                          inventory={
                            store?.productLibrarySearchText && paginatedSearchData?.products
                              ? paginatedSearchData?.products
                              : paginatedProductData?.products
                          }
                          updateProductCallback={handleProductUpdate}
                          triggerError={handleError}
                          toggleAddingProduct={toggleAddingProduct}
                          searching={!!store?.productLibrarySearchText}
                          deleteCallback={deleteCallback}
                          refetchCurrentPageProducts={
                            handleRefetchCurrentPageProducts
                          }
                        />
                      </TabPanel>
                      <TabPanel
                        value={store.selectedProductLibraryPanel}
                        index={1}
                      >
                        <ProductFormatTab
                          inventory={
                            store?.productLibrarySearchText && paginatedSearchData?.products
                              ? paginatedSearchData?.products
                              : paginatedProductData?.products
                          }
                          updateProductCallback={handleProductUpdate}
                          unitsOfMeasurement={
                            unitOfMeasurementData?.unitsOfMeasurement
                          }
                          triggerError={handleError}
                          toggleAddingProduct={toggleAddingProduct}
                          searching={!!store?.productLibrarySearchText}
                          updateExpandedParent={handleUpdateExpandedParent}
                          searchContent={store?.productLibrarySearchText}
                          refetchCurrentPageProducts={
                            handleRefetchCurrentPageProducts
                          }
                        />
                      </TabPanel>
                      <TabPanel
                        value={store.selectedProductLibraryPanel}
                        index={2}
                      >
                        <PurchaseUnitTab
                          inventory={
                            store?.productLibrarySearchText && paginatedSearchData?.products
                              ? paginatedSearchData?.products
                              : paginatedProductData?.products
                          }
                          vendors={vendorData?.vendors}
                          updateProductCallback={handleProductUpdate}
                          triggerError={handleError}
                          toggleAddingProduct={toggleAddingProduct}
                          searching={!!store?.productLibrarySearchText}
                          updateExpandedParent={handleUpdateExpandedParent}
                          searchContent={store?.productLibrarySearchText}
                          expenseGroups={expenseGroupData?.expenseGroups}
                          openExpenseGroupEdit={handleOpenExpenseGroupEdit}
                          openExpenseSubgroupEdit={
                            handleOpenExpenseSubgroupEdit
                          }
                          refetchCurrentPageProducts={
                            handleRefetchCurrentPageProducts
                          }
                          subscriptionData={subscriptionData}
                        />
                      </TabPanel>
                      <TabPanel
                        value={store.selectedProductLibraryPanel}
                        index={3}
                      >
                        <TrainingDetailsTab
                          inventory={
                            store?.productLibrarySearchText && paginatedSearchData?.products
                              ? paginatedSearchData?.products
                              : paginatedProductData?.products
                          }
                          updateProductCallback={handleProductUpdate}
                          triggerError={handleError}
                          toggleAddingProduct={toggleAddingProduct}
                          searching={!!store?.productLibrarySearchText}
                          deleteCallback={deleteCallback}
                        />
                      </TabPanel>
                    </Box>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Pagination
              sx={{ marginTop: 6 }}
              count={
                store?.productLibrarySearchText && paginatedSearchData?.products
                  ? paginatedSearchData?.totalPages
                  : paginatedProductData?.totalPages
              }
              onChange={handlePageSet}
              page={store?.productLibraryPage}
            />
          </Grid>
        )}
      </Grid>
      <Box>
        <Snackbar
          open={alertSuccess ? true : false}
          onClose={() => setAlertSuccess(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" variant="filled">
            {alertSuccess}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMessage ? true : false}
          onClose={() => setErrorMessage(undefined)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Box>
      <CreateExpenseGroupModal
        isOpen={isExpenseGroupModalOpen}
        toggleModalOpen={toggleIsExpenseGroupModalOpen}
        headerText="Edit Groups"
        buttonText="Save List"
        expenseGroups={expenseGroupData.expenseGroups}
      />
      <CreateExpenseSubgroupModal
        isOpen={isExpenseSubgroupModalOpen}
        toggleModalOpen={toggleIsExpenseSubgroupModalOpen}
        headerText="Edit Subgroups"
        buttonText="Save List"
        expenseGroup={expenseGroupForSubgroupEdit}
      />
      <ProductFiltersModal
        currentFilters={store?.productLibrarySortFilters}
        families={familyData?.productFamilies || []}
        isOpen={isProductFilterModalOpen}
        toggleModalOpen={handleCloseProductFilterModal}
        onCompleteCallback={handleSetFilters}
      />
    </>
  );
};

export default ProductLibrary;

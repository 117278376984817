import React, { useContext } from "react";
import { Box, Button, Typography, Grid, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Store from "../../../Store/Store";

const BackButtonStyle = {
  height: "42px",
  borderRadius: "8px",
  marginRight: "16px",
  padding: "8px 64px",
};

const FooterGridWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.terrain[400]}`,
  backgroundColor: theme.palette.terrain[50],
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "80px",
  transition: theme.transitions.create(["width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  display: "flex",
  alignItems: "center",
  padding: "0 24px", // Padding for consistent spacing
  boxSizing: "border-box", // Ensure padding doesn't affect width
}));

const DistributorFormFooter = ({
  activeStep,
  setActiveStep,
  newDistributors,
  submit,
  isAddingDistributors,
}) => {
  const { sideNavOpen } = useContext(Store);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderFooterSection = () => {
    if (activeStep === 0) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ maxWidth: "2440px", margin: "0 auto" }} // Center the content
          >
              <Button
                variant="contained"
                disabled={newDistributors.length === 0}
                onClick={handleNext}
                sx={{
                  width: "148px",
                  height: "42px",
                }}
              >
                <Typography variant="largeButton">Next</Typography>{" "}
              </Button>
          </Grid>
        </FooterGridWrapper>
      );
    }

    if (activeStep === 1 || activeStep === 2 || activeStep === 3) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "2440px",
            }}
          >
            <Grid item xs={sideNavOpen ? 2 : 0} />
            <Grid item xs={sideNavOpen ? 4 : 6}>
              <Button
                variant="outlined"
                sx={BackButtonStyle}
                onClick={handleBack}
              >
                <Typography variant="smallButton">Back</Typography>
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="button"
                variant="contained"
                sx={{ width: "160px", height: "42px" }}
                onClick={handleNext}
              >
                <Typography variant="largeButton">Next</Typography>{" "}
              </Button>
            </Grid>
          </Grid>
        </FooterGridWrapper>
      );
    }

    if (activeStep === 4) {
      return (
        <FooterGridWrapper open={sideNavOpen}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              maxWidth: "2440px",
            }}
          >
            <Grid item xs={sideNavOpen ? 2 : 0} />
            <Grid item xs={sideNavOpen ? 4 : 6}>
              <Button
                variant="outlined"
                sx={BackButtonStyle}
                onClick={handleBack}
              >
                <Typography variant="smallButton">Back</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ width: "160px", height: "42px" }}
                  onClick={submit}
                  disabled={isAddingDistributors}
                >
                  <Typography variant="largeButton">
                    {isAddingDistributors ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      "Complete"
                    )}
                  </Typography>{" "}
                </Button>
            </Grid>
          </Grid>
        </FooterGridWrapper>
      );
    }

    return null;
  };

  return <Box sx={{ width: "100%" }}>{renderFooterSection()}</Box>;
};

export default DistributorFormFooter;

import React from "react";
import {
  Grid,
  Box,
  Typography,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";

const roleOptions = [
  {
    label: "Owner/Operator",
    id: 1,
  },
  {
    label: "Bar/Restaurant Manager",
    id: 2,
  },
  {
    label: "Food + Beverage Director",
    id: 3,
  },
  {
    label: "Chef/Kitchen Manager",
    id: 4,
  },
  {
    label: "Bartender/Server",
    id: 5,
  },
  {
    label: "Barista",
    id: 6,
  },
  {
    label: "Other",
    id: 7,
  },
];

const useCaseOptions = [
  {
    label: "Cost out Recipes",
    id: 1,
  },
  {
    label: "Menu Engineering",
    id: 2,
  },
  {
    label: "Train Staff",
    id: 3,
  },
  {
    label: "Manage Inventory",
    id: 4,
  },
  {
    label: "Place Orders with Vendors",
    id: 5,
  },
  {
    label: "Understand Pour Costs",
    id: 6,
  },
  {
    label: "Analyze Financial Reports",
    id: 7,
  },
];

const AboutYou = ({worksInFoodAndBev, setWorksInFoodAndBev, role, setRole, useCase, setUseCase}) => {
  const handleWorksInUpdate = (e) => {
    setWorksInFoodAndBev(e.target.value);
    if (e.target.value === "No") {
      setRole("Other");
    } else if (e.target.value === "Yes") {
      setRole(null);
    }
  };

  const handleRoleUpdate = (e) => {
    setRole(e.target.value);
  }

  const handleUseCaseUpdate = (e) => {
    setUseCase(e.target.value);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="body1">
            Do you currently work in Food and Beverage?
          </Typography>
        </Box>
        <Select
          value={worksInFoodAndBev}
          label=""
          onChange={handleWorksInUpdate}
          displayEmpty
          size="small"
          input={
            <OutlinedInput
              label="" // No label text
              notched={false} // Disable the notch
              sx={{
                // Hide the legend that creates the notch
                "& legend": { display: "none" },
              }}
            />
          }
          sx={{ width: "100%" }}
        >
          <MenuItem key={"Yes"} value={"Yes"}>
              Yes
            </MenuItem>
            <MenuItem key={"No"} value={"No"}>
              No
            </MenuItem>
        </Select>
      </Grid>

      {worksInFoodAndBev === "Yes" ? (
        <Grid item xs={12} sx={{ marginTop: 6 }}>
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="body1">What is your role?</Typography>
        </Box>
        <Select
          value={role}
          label=""
          onChange={handleRoleUpdate}
          displayEmpty
          size="small"
          input={
            <OutlinedInput
              label="" // No label text
              notched={false} // Disable the notch
              sx={{
                // Hide the legend that creates the notch
                "& legend": { display: "none" },
              }}
            />
          }
          sx={{ width: "100%" }}
        >
          {roleOptions.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      ) : null}
      {role || worksInFoodAndBev === "No" || useCase ? (
        <Grid item xs={12} sx={{ marginTop: 6 }}>
        <Box sx={{ marginBottom: 1 }}>
          <Typography variant="body1">
            What are you most interested in using Spec for?
          </Typography>
        </Box>
        <Select
          value={useCase}
          label=""
          onChange={handleUseCaseUpdate}
          displayEmpty
          size="small"
          input={
            <OutlinedInput
              label="" // No label text
              notched={false} // Disable the notch
              sx={{
                // Hide the legend that creates the notch
                "& legend": { display: "none" },
              }}
            />
          }
          sx={{ width: "100%" }}
        >
          {useCaseOptions.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      ) : null}
    </Box>
  );
};

export default AboutYou;

import { Box, Grid } from "@mui/material";
import React, { useState } from "react";

import EstablishmentCard from "./EstablishmentCard";
import SummaryCard from "./SummaryCard";
import { useParams } from "react-router-dom";
import UpgradeAccountModal from "../../../components/Modals/UpgradeAccountModal";
import UpgradeCard from "./UpgradeCard";
import { createStripeCheckoutSession } from "../../../clients/establishmentsClient";
import DashboardTutorials from "../components/DashboardTutorials";

const FreemiumDashboard = ({
  inventoryReportingOverview,
  staffMemberCount,
  establishment,
  memberships,
  tutorialData,
}) => {
  const { establishmentid: establishmentId } = useParams();

  const [isLoadingSession, toggleLoadingSession] = useState(false);
  const [upgradeAccountModalOpen, setUpgradeAccountModalOpen] = useState(false);

  const handleModalToggle = () => {
    setUpgradeAccountModalOpen(!upgradeAccountModalOpen);
  };

  const handleCreateCheckout = async (interval) => {
    toggleLoadingSession(true);
    await createStripeCheckoutSession(establishmentId, interval);
    toggleLoadingSession(false);
  };

  const isEstablishmentOwner =
    memberships?.establishmentMemberships?.find(
      (x) => x.establishmentId === establishmentId
    )?.isOwner === true
      ? true
      : false;
  return (
    <Box sx={{ marginTop: 6 }}>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <EstablishmentCard
            establishment={establishment}
            handleModalToggle={handleModalToggle}
            isFreeAccount
          />
        </Grid>
        <Grid item xs={4} sm={3} md={3}>
          <SummaryCard
            header="Staff Members"
            amount={staffMemberCount}
            targetRoute={`/homebase/${establishmentId}/staffmanagement`}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={3}>
          <SummaryCard
            header="Recipes"
            amount={inventoryReportingOverview?.recipeCount || 0}
            targetRoute={`/spec/${establishmentId}/recipes`}
            displayRestrictions
          />
        </Grid>
        <Grid item xs={4} sm={3} lg={3}>
          <SummaryCard
            header="Menus"
            amount={inventoryReportingOverview?.menuCount || 0}
            targetRoute={`/spec/${establishmentId}/menus`}
            displayRestrictions
          />
        </Grid>

        <Grid item xs={4} sm={3} lg={3}>
          <SummaryCard
            header="Products"
            amount={inventoryReportingOverview?.productCount || 0}
            targetRoute={`/spec/${establishmentId}/productlibrary`}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={3}>
          <SummaryCard
            header="Vendors"
            amount={inventoryReportingOverview.vendorCount}
            targetRoute={`/spec/${establishmentId}/vendors`}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={3}>
          <SummaryCard
            header="Orders"
            amount={inventoryReportingOverview.orderCount}
            targetRoute={`/spec/${establishmentId}/ordering`}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 4, marginRight: 2 }}>
        <DashboardTutorials tutorialData={tutorialData} />
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 4, marginRight: 2 }}>
        <UpgradeCard handleModalToggle={handleModalToggle} />
      </Grid>

      <UpgradeAccountModal
        isOpen={upgradeAccountModalOpen}
        handleModalToggle={handleModalToggle}
        isLoadingSession={isLoadingSession}
        isOwner={isEstablishmentOwner}
        upgradeCallback={handleCreateCheckout}
      />
    </Box>
  );
};

export default FreemiumDashboard;

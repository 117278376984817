import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SubscriptionCellLock from "../../../components/SubscriptionCellLock";
import DescriptionToolTip from "../../../components/DescriptionToolTip";

const infoBoxStyle = {
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: (theme) => theme.palette.terrain[100],
  height: "35px",
  borderRadius: 1,
  paddingLeft: 2,
  paddingRight: 2,
  marginTop: 2,
  marginBottom: 2,
};

const OrganizationCard = ({ handleClick, icon, organizationData }) => {
  const nothingAction = () => {};

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onClick={
        organizationData.isSubscriptionDisabled ? nothingAction : handleClick
      }
    >
      <Box
        sx={{
          height: "120px",
          backgroundColor: (theme) => theme.palette.terrain[200],
          border: "2px solid",
          borderBottom: "none",
          borderColor: (theme) => theme.palette.terrain[300],
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          height: "200px",
          backgroundColor: (theme) => theme.palette.pure.white,
          border: "2px solid",
          borderTop: "none",
          borderColor: (theme) => theme.palette.terrain[300],
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 4,
          }}
        >
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              variant="h3"
              sx={{
                color: (theme) => theme.palette.primary[800],
                fontSize: { xs: 18, md: 24 },
              }}
            >
              {organizationData.name}
            </Typography>
            <DescriptionToolTip
              header={organizationData.toolTipHeader}
              description={<Box>{organizationData.toolTipDescription}</Box>}
              colorPrimary={true}
            />
            {organizationData?.isSubscriptionDisabled ? (
              <Box
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: 2,
                }}
              >
                <SubscriptionCellLock />
              </Box>
            ) : null}
          </Grid>
          <Grid item sx={infoBoxStyle}>
            <Typography variant="body1">
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  maxHeight: "40px",
                }}
              >
                {organizationData.subHeader1}
              </div>
            </Typography>
            <Typography variant="body2">
              {organizationData?.isSubscriptionDisabled ? (
                <SubscriptionCellLock />
              ) : (
                organizationData.subHeader1Data
              )}
            </Typography>
          </Grid>
          <Grid item sx={infoBoxStyle}>
            <Typography variant="body1">
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                  maxHeight: "40px",
                }}
              >
                {organizationData.subHeader2}
              </div>
            </Typography>
            <Typography variant="body2">
              {organizationData?.isSubscriptionDisabled ? (
                <SubscriptionCellLock />
              ) : (
                organizationData.subHeader2Data
              )}
            </Typography>
          </Grid>
          {organizationData.subHeader3 ? (
            <Grid item sx={infoBoxStyle}>
              <Typography variant="body1">
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    maxHeight: "40px",
                  }}
                >
                  {organizationData.subHeader3}
                </div>
              </Typography>
              <Typography variant="body2">
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    maxHeight: "40px",
                  }}
                >
                  {organizationData?.isSubscriptionDisabled ? (
                    <SubscriptionCellLock />
                  ) : (
                    organizationData.subHeader3Data
                  )}
                </div>
              </Typography>
            </Grid>
          ) : (
            <Box sx={{ height: "35px", marginTop: 2, marginBottom: 2 }} />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default OrganizationCard;

import React from "react";
import { Button, Typography, Box, Modal } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { modalBoxStyle } from "../styles";

const AddVendorRemovalConfirmationModal = ({
  isOpen,
  closeModal,
  confirmationCallback,
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalBoxStyle}>
        <Box>
          <Box
            sx={{
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DeleteOutlineOutlinedIcon sx={{ fontSize: "60px" }} />
          </Box>
          <Box
            sx={{
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography variant="h2">Remove Distributor</Typography>
          </Box>
          <Box
            sx={{
              marginBottom: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              {"Are you sure you want to remove this vendor? "}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", marginTop: 1 }}
            >
              {"All data entered so far will be lost."}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{ borderRadius: 2 }}
              variant="outlined"
              onClick={() => {
                closeModal();
              }}
            >
              <Typography variant="smallButton">No, Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{ borderRadius: 2, marginLeft: 2 }}
              onClick={confirmationCallback}
            >
              <Typography variant="smallButton">Yes, Delete</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddVendorRemovalConfirmationModal;

import React from "react";
import { Grid, TextField, Box } from "@mui/material";

import MuiPhoneNumber from "material-ui-phone-number";
import { Controller } from "react-hook-form";

const FormInputLabelStyles = {
  shrink: true,
};

const TextFieldStyles = {
  width: "100%",
};

const PersonalDetails = ({ register, selectedCountry, control, errors }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        spacing={{ xs: 1, md: 4 }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="First Name"
            id="first-name"
            name="firstName"
            placeholder="Enter First Name"
            InputLabelProps={FormInputLabelStyles}
            sx={TextFieldStyles}
            {...register("firstName", {
              required: true,
              message: "Please enter a first name",
            })}
          />
          {errors.firstName ? (
            <Box
              sx={{
                color: "red",
                marginTop: "0px",
                height: 10,
              }}
            >
              {errors.firstName?.message}
            </Box>
          ) : (
            <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            label="Last Name"
            id="last-name"
            placeholder="Enter Last Name"
            InputLabelProps={FormInputLabelStyles}
            sx={TextFieldStyles}
            {...register("lastName", { required: true })}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 1, md: 4 }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Grid item xs={6}>
          <Box>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MuiPhoneNumber
                  size="small"
                  onChange={onChange}
                  value={value}
                  sx={TextFieldStyles}
                  defaultCountry={selectedCountry}
                  disableAreaCodes
                  label="Mobile Number"
                  placeholder="Enter remaining digits"
                  InputLabelProps={FormInputLabelStyles}
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            required
            sx={TextFieldStyles}
            label="Email"
            id="email"
            type="email"
            placeholder="Enter Email Address"
            InputLabelProps={FormInputLabelStyles}
            name="email"
            variant="outlined"
            {...register("email", {
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address",
              },
            })}
          />
          {errors.email ? (
            <Box
              sx={{
                color: "red",
                marginTop: "0px",
                height: 10,
              }}
            >
              {errors.email?.message}
            </Box>
          ) : (
            <Box style={{ height: 10, marginTop: "0px" }}>{""}</Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDetails;

import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import SpecBackButton from "../../components/SpecBackButton";
import Store, { activeRouteKeys } from "../../Store/Store";
import useRecipe from "../../hooks/useRecipe";
import SpecSpinner from "../../components/SpecSpinner";
import { useNavigate, useParams } from "react-router-dom";
import RecipeForm from "./components/RecipeForm";
import useEstablishmentUoMs from "../../hooks/useEstablishmentUoMs";
import useProductLibrary from "../../hooks/useProductLibrary";
import useEstablishmentRecipes from "../../hooks/useEstablishmentRecipes";
import useEstablishment from "../../hooks/useEstablishment";
import useEstablishmentSubscription from "../../hooks/useEstablishmentSubscription";
import useEstablishmentProductFamilyGroups from "../../hooks/useEstablishmentProductFamilyGroups";
import useVendors from "../../hooks/useVendors";
import useEstablishmentExpenseGroups from "../../hooks/useEstablishmentExpenseGroups";

const RecipeDetails = () => {
  const navigate = useNavigate();
  const store = useContext(Store);
  let { recipeid: recipeId, establishmentid } = useParams();
  useEstablishment(establishmentid, store);
  const { isLoading: isLoadingRecipe, data: recipeData } = useRecipe(
    establishmentid,
    recipeId
  );
  const { data: establishmentUoMsData, isLoading: isLoadingUoMs } =
    useEstablishmentUoMs(establishmentid);
  const [
    { isLoading: isLoadingInventory, data: productLibraryData },
    productMap,
  ] = useProductLibrary(establishmentid);
  const {
    data: establishmentRecipesData,
    isLoading: isLoadingEstablishmentRecipes,
  } = useEstablishmentRecipes(establishmentid);
  const { data: subscriptionData, isLoading: isLoadingSubscription} = useEstablishmentSubscription(establishmentid);
  const { data: familyData, isLoading: isLoadingFamilyData } =
      useEstablishmentProductFamilyGroups(establishmentid);
  const [{ data: vendorData, isLoading: isLoadingVendors }] =
      useVendors(establishmentid);
  const { data: expenseGroupData, isLoading: isLoadingExpenseData } =
      useEstablishmentExpenseGroups(establishmentid);

  useEffect(() => {
    store.updateActiveRoute(
      activeRouteKeys.recipes,
      `spec/${establishmentid}/recipes/${recipeId}`
    );
  }, []);

  if (
    isLoadingRecipe ||
    isLoadingUoMs ||
    isLoadingInventory ||
    isLoadingEstablishmentRecipes ||
    isLoadingSubscription ||
    isLoadingFamilyData ||
    isLoadingVendors ||
    isLoadingExpenseData
  ) {
    return <SpecSpinner open text="Loading Recipe..." />;
  }

  return (
    <Box
      container
      sx={{
        width: "100%",
        maxWidth: "2440px",
        padding: 4,
        marginTop: 6,
      }}
    >
      <Box>
        <Box>
          <SpecBackButton
            backText="All Recipes"
            onClick={() => navigate(`/spec/${establishmentid}/recipes`)}
          />
        </Box>
        <RecipeForm
          recipe={recipeData.recipe}
          establishmentUoMs={establishmentUoMsData.unitsOfMeasurement}
          productLibrary={productLibraryData.products}
          productLibraryMap={productMap}
          establishmentRecipes={establishmentRecipesData?.recipes}
          subscriptionState={subscriptionData}
          familyData={familyData}
          vendorData={vendorData}
          expenseGroupData={expenseGroupData}
        />
      </Box>
    </Box>
  );
};

export default RecipeDetails;
